import React from "react"
import Layout from "@app-components/layout/"
import Feature from "@app-components/ui/feature/"
import Header from "@app-components/ui/header/side_by_side/"
import HEADERS from "@data/headers/"
import HeaderImage from "@page-components/products/venues/media/header/"
import DeviceImage from "@page-components/products/venues/media/devices/"
import RequestsImage from "@page-components/products/venues/media/requests/"
import MonitorImage from "@page-components/products/venues/media/monitor/"

const Venues = () => (
  <Layout
    getStartedButtons={["demo", "contact-sales"]}
    page="products/venues"
    getStartedText="Sign up today and become your festival's print traffic hero.">
    <Header
      title="Electronic Delivery Dashboard"
      text="Connect one or more CSX broadband receivers to manage downloads, request content, and manage device information at your cinema location(s)."
      image={<HeaderImage />}
      secondaryNavigation={HEADERS.venues}
      buttons={[
        {
          text: <div className="red">Get Started</div>,
          className: "web_white",
          type: "link",
          link: {
            to: "https://accounts.cinesend.com",
            type: "external"
          }
        },
        {
          text: "Schedule a Demo",
          className: "web_white_outline ",
          type: "link",
          link: {
            to: "/demo",
            type: "internal"
          }
        }
      ]}
    />
    <Feature
      title="Control and Monitoring"
      text="Monitor incoming transfers, view and delete content, setup time-of-day bandwidth controls, and copy content to/from USB3 storage devices."
      icon="url-white"
      image={<MonitorImage />}
    />
    <Feature
      title="Direct Content Requests"
      reverse
      text="A fully decentralized system enables theatre managers to request content directly from filmmakers or distributors for private “four wall” events, festivals, press or trade screenings."
      icon="download-white"
      image={<RequestsImage />}
    />
    <Feature
      title="Device Management"
      text="Automatically update the CineSend global Trusted Device List (TDL) when you upgrade or swap servers to ensure KDMs target the right devices on the right screens. It’s also possible to publish an FLM-X feed to keep other KDM issuers up to date."
      icon="hardware-white"
      image={<DeviceImage />}
    />
  </Layout>
)

Venues.propTypes = {}

export default Venues
